





























































import Vue from "vue";

export default Vue.extend({
  name: "SimpleUploadComponent",
  props: {
    endpoint: String,
    title: String,
    timeout: {
      type: Number,
      default: 0,
    },
    accept: {
      type: String,
      required: false,
    },
    interceptFeedback: {
      type: Boolean,
      required: false,
      default: false,
    },
    uploadName: {
      type: String,
      required: false,
    },
    buttonText: {
      type: String,
      required: false,
      default: "Upload",
    },
  },
  data: () => ({
    error: "",
    success: "",
    loading: false,
    files: [] as File[],
    trackerName: ""
  }),
  methods: {
    clearFileInput() {
      this.files = [];
      (this.$refs.file as HTMLInputElement).value = "";
    },
    uploadFile(e: { target: { files: any } }) {
      this.files = e.target.files;
    },
    dragFile(e: { dataTransfer: { files: any } }) {
      this.files = e.dataTransfer.files;
      if (this.files.length > 1) {
        // only allow a single file
        this.files = [this.files[0]];
      }
    },
    clickDropZone() {
      document.getElementById(this.hiddenFileInputId)?.click();
    },
    exceedFileSize(max_mb: number): boolean {
      let max_size = max_mb * Math.pow(10.0, 6);
      return this.files[0].size > max_size;
    },
    upload() {
      this.loading = true;
      if (this.files.length > 1) {
        this.error = "Kan niet meer dan 1 bestand tegelijk uploaden";
        return;
      }
      this.error = "";
      const formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append("documents", this.files[i]);
      }
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.$store.state.user.token}`,
      };
      let url = `${this.endpoint}`;
      this.trackerName = `${this.uploadName}-${Date.now()}`
      url = url + `?tracker_name=${this.trackerName}`
      this.$request
        .post<FormData>(url, formData, {
          headers: headers,
          timeout: this.timeout,
        })
        .then((response: any) => {
          this.success = response.data;
        })
        .catch((error: any) => {
          if (error.code == "ECONNABORTED") {
            this.error = "Er is een time-out opgetreden.";
          } else {
            this.error = error.response.data.detail;
          }
        })
        .finally(() => {
          this.error = "";
          this.success = "";
          this.loading = false;
          this.files = [];
        });
    },
  },
  computed: {
    hiddenFileInputId(): string {
      return "hidden-file-input" + this.uploadName;
    },
  },
  watch: {
    error: function (val) {
      if (val) this.$emit("error", this.error);
    },
    success: function (val) {
      if (val) this.$emit("success", {
        success: this.success,
        trackerName: this.trackerName
        });
    },
  },
});
