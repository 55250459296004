import {ActionTypes, ErrorTypes, SectionList, TableButtons,} from '@/types/management'

const sections: SectionList = [
  {
    name: "import-stamdata",
    title: "Importeer stamdata",
    instruction: "Importeer de stamdata in het Spendportaal."+
    "<br> Gebruik de automatische S3 verbinding met de DWH, of importeer de bestanden handmatig",
    baseEndpoint: "/beheer/stamdata-import",
    actions: [
      {
        name: "year-selector",
        instruction:
          "Selecteer het jaar van aanlevering",
        buttonText: "Selecteer",
        actionType: ActionTypes.CustomComponent,
        componentName: "year-selector",
      },
      {
        name: "fetch-dwh-kvk-dump",
        instruction: "Laad de KvK-dump uit het datawarehouse in.",
        buttonText: "Importeer KvK-dump",
        actionType: ActionTypes.SimpleApiCall,
        showProgress: true,
        errorMessages: {
          [ErrorTypes.DEFAULT]:
            "Er is een fout opgetreden bij het inladen van de KvK-dump. Probeer het later opnieuw.",
        },
        successMessage: "De KvK-dump is succesvol ingeladen uit het datawarehouse.",
      },
      {
        name: "fetch-dwh-historical-data",
        showProgress: true,
        instruction: "Laad het bestand met de Spenddata van het voorgaande jaar in.",
        buttonText: "Importeer historische data",
        actionType: ActionTypes.SimpleApiCall,
        errorMessages: {
          [ErrorTypes.DEFAULT]:
            "Er is een fout opgetreden bij het inladen van de historische spenddata. Probeer het later opnieuw.",
        },
        successMessage: "Het bestand met historische spenddata wordt in de achtergrond opgehaald uit het datawarehouse.",
      },
      {
        name: "fetch-dwh-landcodes",
        instruction: "Laad het bestand met de landenlijst in.",
        buttonText: "Importeer landenlijst",
        actionType: ActionTypes.SimpleApiCall,
        errorMessages: {
          [ErrorTypes.DEFAULT]:
            "Er is een fout opgetreden bij het inladen van de landenlijst. Probeer het later opnieuw.",
        },
      },
      {
        name: "fetch-dwh-rubrieken",
        instruction: "Laad het bestand met de rubrieken en subrubrieken in.",
        buttonText: "Importeer rubriekenlijst",
        actionType: ActionTypes.SimpleApiCall,
        errorMessages: {
          [ErrorTypes.DEFAULT]:
            "Er is een fout opgetreden bij het inladen van de rubriekenlijst. Probeer het later opnieuw.",
        },
      },
      {
        name: "fetch-dwh-organizations",
        instruction: "Laad de organisatielijst uit het datawarehouse in.",
        buttonText: "Importeer organisatielijst",
        actionType: ActionTypes.SimpleApiCall,
        errorMessages: {
          [ErrorTypes.DEFAULT]:
            "Er is een fout opgetreden bij het inladen van de organisatielijst. Probeer het later opnieuw.",
        },
      },
      {
        name: "kvk-dump-upload",
        instruction: "Upload een recente KvK-dump (csv)",
        buttonText: "Upload",
        actionType: ActionTypes.FileUpload,
        showProgress: true,
        errorMessages: {
          [ErrorTypes.DEFAULT]:
            "Er is een fout opgetreden bij het uploaden van de KvK-dump. Probeer het later opnieuw.",
        },
        successMessage: "De KvK-dump is succesvol geüpload.",
      },
      {
        name: "historical-data-upload",
        instruction:
          "Upload een bestand met de Spenddata van het voorgaande jaar. (csv)",
        buttonText: "Upload",
        actionType: ActionTypes.FileUpload,
        showProgress: true,
        errorMessages: {
          [ErrorTypes.DEFAULT]:
            "Er is een fout opgetreden bij het uploaden van de historische spenddata. Probeer het later opnieuw.",
        },
        successMessage:
          "Het bestand met historische spenddata is succesvol geüpload.",
      },
      {
        name: "upload-organizations",
        instruction: "Upload een bestand met de organisatiedata. (csv)",
        buttonText: "Upload organisaties",
        actionType: ActionTypes.FileUpload,
        errorMessages: {
          [ErrorTypes.DEFAULT]:
            "Er is een fout opgetreden bij het uploaden van de organisatiedata. Probeer het later opnieuw.",
        },
      },
      {
        name: "upload-landcodes",
        instruction:
          "Update het bestand met de landenlijst. (csv)",
        buttonText: "Upload landenlijst",
        actionType: ActionTypes.FileUpload,
        errorMessages: {
          [ErrorTypes.DEFAULT]:
            "Er is een fout opgetreden bij het uploaden van de historische spenddata. Probeer het later opnieuw.",
        },
        successMessage:
          "De landenlijst is bijgewerkt.",
      },
      {
        name: "upload-rubrieken",
        instruction:
          "Update het bestand met de rubrieken. (csv)",
        buttonText: "Upload rubrieken",
        actionType: ActionTypes.FileUpload,
        errorMessages: {
          [ErrorTypes.DEFAULT]:
            "Er is een fout opgetreden bij het uploaden van de historische spenddata. Probeer het later opnieuw.",
        },
        successMessage:
          "Het bestand met rubrieken is succesvol geüpload.",
      },
      {
        name: "upload-submission-template",
        instruction:
          "Update template voor de spenddata aanlevering. (xlsx)",
        buttonText: "Upload aanlever template",
        actionType: ActionTypes.FileUpload,
        errorMessages: {
          [ErrorTypes.DEFAULT]:
            "Er is een fout opgetreden bij het uploaden van het bestand. Probeer het later opnieuw.",
        },
        successMessage:
          "De aanlever template is succesvol geüpload.",
      },
      {
        name: "upload-submission-instructions",
        instruction:
          "Update het bestand met de spenddata aanlevering instructies. (pdf)",
        buttonText: "Upload aanlever instructies",
        actionType: ActionTypes.FileUpload,
        errorMessages: {
          [ErrorTypes.DEFAULT]:
            "Er is een fout opgetreden bij het uploaden van het bestand. Probeer het later opnieuw.",
        },
        successMessage:
          "Het bestand met aanlever instructies is succesvol geüpload.",
      },
      {
        name: "upload-kvk-instructions",
        instruction:
          "Update het bestand met de KvK instructies. (pdf)",
        buttonText: "Upload KvK instructies",
        actionType: ActionTypes.FileUpload,
        errorMessages: {
          [ErrorTypes.DEFAULT]:
            "Er is een fout opgetreden bij het uploaden van het bestand. Probeer het later opnieuw.",
        },
        successMessage:
          "Het bestand met KvK instructies is succesvol geüpload.",
      },
      {
        name: "upload-opendata-instructions",
        instruction:
          "Update het bestand met de Opendata instructies. (pdf)",
        buttonText: "Upload Opendata instructies",
        actionType: ActionTypes.FileUpload,
        errorMessages: {
          [ErrorTypes.DEFAULT]:
            "Er is een fout opgetreden bij het uploaden van het bestand. Probeer het later opnieuw.",
        },
        successMessage:
          "Het bestand met Opendata instructies is succesvol geüpload.",
      },
      {
        name: "download-expired",
        instruction: "Download niet-actuele spenddata",
        buttonText: "Download niet-actuele spenddata",
        actionType: ActionTypes.FileDownload,
        fileType: "application/x-zip-compressed",
        outputFileName: "niet-actuele_spenddata.zip",
      },
      {
        name: "download-topical",
        instruction: "Download actuele spenddata",
        buttonText: "Download actuele spenddata",
        actionType: ActionTypes.FileDownload,
        fileType: "application/x-zip-compressed",
        outputFileName: "actuele_spenddata.zip",
      },
    ],
  },
  {
    name: "portal-preparation",
    title: "Open het portaal voor eindgebruikers",
    instruction:
      "Open het portaal voor de eindgebruikers. Hier worden de accounts aangemaakt en beheerd" +
      "<br> Om naar de volgende stap te gaan moet het portaal worden geopend",
    baseEndpoint: "/beheer/portal-preparation",
    actions: [
      {
        name: "manage-accounts",
        instruction: "Controleer wie een account heeft en pas accounts aan",
        buttonText: "Beheer accounts",
        actionType: ActionTypes.PopUpTable,
        tableButtons: [
          TableButtons.Create,
          TableButtons.Edit,
          TableButtons.Migrate,
          TableButtons.Impersonate,
          TableButtons.Delete
        ],
        tableTitle: "Account",
        tableFormComponents: [
          "migrate-user",
          "register-user"
        ],
        disableLoading: true,
      },
      {
        name: "open-portal",
        instruction: "Stel het portaal open",
        buttonText: "Open het portaal",
        actionType: ActionTypes.SimpleApiCall,
        disableLoading: true,
      },
      {
        name: "send-invites",
        instruction: "Stuur alle accounts een uitnodiging",
        buttonText: "Verstuur uitnodigingen",
        actionType: ActionTypes.SimpleApiCall,
        disableLoading: true,
      },
    ],
  },
  {
    name: "market-share",
    title: "Marktaandeel beheer",
    instruction:
      "Beheer de vastgestelde marktaandelen. " +
      "<br>Deze data kan verschillen van de definitieve aanlevering aangezien de eisen van de aangeleverde data lager zijn",
    baseEndpoint: "/beheer/market-share",
    actions: [
      {
        name: "overview",
        instruction: "Bekijk het marktaandeeloverzicht",
        buttonText: "Naar overzicht",
        actionType: ActionTypes.PopUpTable,
        tableButtons: [
          TableButtons.Revert,
          TableButtons.Fix,
          TableButtons.Download,
        ],
        tableTitle: "Marktaandeel",
      },
      {
        name: "download-excel",
        instruction: "Download alle marktaandeel data",
        buttonText: "Download",
        actionType: ActionTypes.FileDownload,
        fileType: "application/x-zip-compressed",
        outputFileName: "alle marktaandelen.zip",
      },
      {
        name: "download-recap",
        instruction: "Download het marktaandeeloverzicht",
        buttonText: "Download",
        actionType: ActionTypes.FileDownload,
        fileType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        outputFileName: "marktaandeel overzicht.xlsx",
      },
    ],
  },
  {
    name: "portal-progress",
    title: "Aanlevering beheer",
    instruction:
      "Beheer de aanleveringen van de gebruikers." +
      "<br> Om naar de volgende stap te gaan moet voor alle gebruikers alle data zijn aangeleverd en vastgesteld",
    baseEndpoint: "/beheer/portal-progress",
    actions: [
      {
        name: "manage-documents",
        instruction: "Bekijk de status van aanleveringen.",
        buttonText: "Naar bestandbeheer",
        actionType: ActionTypes.PopUpTable,
        tableButtons: [
          TableButtons.Revert,
          TableButtons.Delete,
          TableButtons.Download,
        ],
        tableTitle: "Aanlevering",
      },
      {
        name: "send-reminders-upload",
        instruction: "Stuur eindgebruikers een reminder om aan te leveren",
        buttonText: "Verstuur reminders",
        actionType: ActionTypes.SimpleApiCall,
        disableLoading: true,
      },
      {
        name: "send-reminders-fix",
        instruction: "Stuur eindgebruikers een reminder om vast te stellen",
        buttonText: "Verstuur reminders",
        actionType: ActionTypes.SimpleApiCall,
        disableLoading: true,
      },
      {
        name: "download-all-submitted",
        instruction: "Download alle aanleveringen",
        buttonText: "Download",
        actionType: ActionTypes.FileDownload,
        fileType: "application/x-zip-compressed",
        outputFileName: "gecombineerde_aanleveringen.zip",
      },
      {
        name: "manage-documents",
        instruction: "Forceer de vaststelling van bestanden.",
        buttonText: "Naar vaststeloverzicht",
        actionType: ActionTypes.PopUpTable,
        themeColor: "error",
        tableButtons: [
          TableButtons.Download,
          TableButtons.ForcedFix
        ],
        tableTitle: "Aanlevering",
      },
    ],
  },
  {
    name: "merge-by-department",
    title: "Departementen samenvoegen",
    instruction:
      "Selecteer een departement en de aanleveringen voor alle onderdelen samen tot één bestand. " +
      "<br> Indien nodig worden leverancierscodes uniek gemaakt en dubbele leveranciers worden gemarkeerd in de download" +
      "<br> Om naar de volgende stap te gaan moet minimaal één departement zijn samengevoegd. Zorgen dat alles is samengevoegd is uw verantwoordelijkheid",
    baseEndpoint: "/beheer/combine",
    actions: [
      {
        name: "generate-combined-doc",
        instruction: "Selecteer een departement en voeg de gegevens samen",
        buttonText: "Voeg samen",
        actionType: ActionTypes.SelectionApiCall,
        actionEndpoint: "/beheer/combine/generate-combined-doc?org_id={}",
        additionalEndpoint: "/beheer/combine/organizations",
        showProgress: true,
        errorMessages: {
          [ErrorTypes.NO_SELECTION]:
            "Selecteer een departement om de gegevens samen te voegen.",
          [ErrorTypes.FILE_NOT_FOUND]:
            "Kan geen vastgestelde bestanden vinden om te combineren voor het geselecteerde departement.",
        },
        successMessage:
          "De gegevens voor het geselecteerde departement zijn succesvol samengevoegd",
      },
      {
        name: "download-combined-doc",
        instruction: "Download het samengevoegde bestand",
        buttonText: "Download",
        actionType: ActionTypes.FileDownload,
        fileType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        outputFileName: "samengevoegd_document.xlsx",
      },
      {
        name: "download-all-combined",
        instruction: "Download totaalbestand van alle ministeries",
        buttonText: "Download",
        actionType: ActionTypes.FileDownload,
        fileType: "application/x-zip-compressed",
        outputFileName: "gecombineerd_totaalbestand.zip",
      },
    ],
  },
  {
    name: "kvk-matching",
    title: "KvK matchen",
    instruction:
      "Match alle leveranciers met de data van het KvK handelsregister. " +
      "Na controle in de volgende stap zal de gematchte data aangevuld en overschreven worden met de informatie uit de KvK" +
      "<br><strong>LET OP</strong>: Na het matchen wordt de volgende stap beschikbaar voor gebruikers. Opnieuw matchen met aangepaste data kan problemen veroorzaken",
    baseEndpoint: "/beheer/kvk",
    actions: [
      {
        name: "perform-match",
        instruction: "Match alle aanleveringen met het handelsregister",
        buttonText: "Voer match uit",
        availabilityCheck: "/beheer/kvk/files-available",
        actionEndpoint: "/beheer/kvk/perform-match",
        actionType: ActionTypes.SimpleApiCall,
        showProgress: true,
        successMessage:
          "De match wordt uitgevoerd in de achtergrond, dit kan even duren.",
      },
      {
        name: "download-match-results",
        instruction: "Bekijk de matchresultaten.",
        buttonText: "Download resultaten",
        availabilityCheck: "/beheer/kvk/results-available",
        actionEndpoint: "/beheer/kvk/download",
        actionType: ActionTypes.FileDownload,
        fileType: "text/csv",
        outputFileName: "kvk_match_resultaten.csv",
        errorMessages: {
          [ErrorTypes.FILE_NOT_FOUND]:
            "Er zijn geen matchresultaten gevonden om te downloaden.",
        },
      },
    ],
  },
  {
    name: "finalize-kvk",
    title: "KvK matches bevestigen",
    instruction:
    "Beheer de KvK teruglevering van de gebruikers. " +
    "Contactpersonen kunnen nu de resultaten van de KvK match downloaden, twijfelgevallen oplossen en eventuele mismatches afkeuren." +
    "<br> Om naar de volgende stap te gaan, klik op 'Afronden'.",
    baseEndpoint: "/beheer/finalize-kvk",
    actions: [
      {
        name: "overview",
        instruction: "Bekijk het overzicht van kvk teruggaven",
        buttonText: "Naar overzicht",
        actionType: ActionTypes.PopUpTable,
        tableButtons: [
          TableButtons.Revert,
          TableButtons.Download,
        ],
        tableTitle: "KVK Teruggave",
        outputFileName: "kvk-teruggave.xlsx",
      },
      {
        name: "send-kvk-mail",
        instruction:
          "Stuur contactpersonen een mail om de kvk-matching te controleren",
        buttonText: "Verstuur mail",
        actionType: ActionTypes.SimpleApiCall,
        disableLoading: true,
      },
      {
        name: "download-kvk-returns",
        instruction: "Download alle teruggeleverde kvk data",
        buttonText: "Download",
        actionType: ActionTypes.FileDownload,
        fileType: "application/x-zip-compressed",
        outputFileName: "kvk_teruggaves.zip",
      },
      {
        name: "export-good-matches",
        instruction: "Afronden KVK teruggave",
        buttonText: "Afronden",
        actionType: ActionTypes.SimpleApiCall,
      },
    ],
  },
  {
    name: "np-check",
    title: "Natuurlijke persoonsgegevens",
    instruction:
      "Controleer de niet gematchte leveranciers voor natuurlijke persoonsgegevens in de aanleveringen. "+
      "Vele leveranciers worden automatisch uitgesloten als NP, maar de rest moet handmatig gecontrolleerd worden" +
      "<br> Om naar de volgende stap te gaan, voltooi de handmatige controle",
    baseEndpoint: "/beheer/np-check",
    actions: [
      {
        name: "auto-np-check",
        instruction:
          "Voer de automatische controle op natuurlijke personen uit",
        buttonText: "Start automatische controle",
        actionType: ActionTypes.SimpleApiCall,
        showProgress: true,
      },
      {
        name: "download-auto-np-results",
        instruction: "Download resultaten automatische NP controle",
        buttonText: "Download",
        actionType: ActionTypes.FileDownload,
        fileType: "text/csv",
        outputFileName: "resultaten_auto_np.csv",
      },
      {
        name: "finalize-np",
        instruction:
          "Bekijk en verifieer de potentiele natuurlijke personen",
        buttonText: "Naar aanpassen",
        actionType: ActionTypes.CustomComponent,
        componentName: "np-editor",
      },
    ],
  },
  {
    name: "prepare-opendata",
    title: "Samenvoegen van data",
    instruction:
      "Koppel de leveranciersdata weer met de spenddata" +
      "<br> Om naar de volgende stap te gaan, klik op 'Combineer', of download en upload de gecombineerde data handmatig. " +
      "<br> De handmatige optie is enkel nodig indien het voorgaande proces niet correct is verlopen of aanpassingen gemaakt moeten worden",
    baseEndpoint: "/beheer/prepare-opendata",
    actions: [
      {
        name: "recombine-data",
        instruction: "Voeg spend- en leveranciersdata samen",
        buttonText: "Combineer",
        actionType: ActionTypes.SimpleApiCall,
      },
      {
        name: "recombine-and-download",
        instruction: "(Optioneel) Indien handmatige aanpassingen nodig zijn, download alle data",
        buttonText: "Download",
        actionType: ActionTypes.FileDownload,
        fileType: "application/x-zip-compressed",
        outputFileName: "gecombineerde_data.zip",
      },
      {
        name: "upload-combined",
        instruction: "(Optioneel) Upload een gecorrigeerde versie van de gecombineerde data",
        buttonText: "Upload",
        actionType: ActionTypes.FileUpload,
        showProgress: true,
        actionEndpoint: "/beheer/prepare-opendata/upload-combined",
        errorMessages: {
          [ErrorTypes.DEFAULT]:
            "Er is een fout opgetreden bij het uploaden. Probeer het later opnieuw.",
        },
        successMessage: "De OpenData is succesvol geüpload.",
      },
    ],
  },
  {
    name: "open-data-progress",
    title: "Voortgang Open-data",
    instruction: "Beheer de teruggave van de Open-data. " +
    "Contactpersonen kunnen nu de aanleveringen downloaden. "+
    "<br>(exclusief foreign posts, overheidspartijen, externe inhuur of natuurlijke personen)",
    baseEndpoint: "/beheer/open-data",
    actions: [
      {
        name: "overview",
        instruction: "Bekijk het overzicht van opendata teruggaven",
        buttonText: "Naar overzicht",
        actionType: ActionTypes.PopUpTable,
        tableButtons: [
          TableButtons.Revert,
          TableButtons.Download,
        ],
        tableTitle: "OpenData Teruggave",
      },
      {
        name: "send-open-data-mail",
        instruction:
          "Stuur alle contactpersonen een mail notificatie dat de Open-data klaar is om in te vullen",
        buttonText: "Verstuur mail",
        actionType: ActionTypes.SimpleApiCall,
      },
      {
        name: "download-open-data-returns",
        instruction: "Download alle teruggeleverde opendata",
        buttonText: "Download",
        actionType: ActionTypes.FileDownload,
        fileType: "application/x-zip-compressed",
        outputFileName: "opendata_teruggaves.zip",
      },
    ],
  },
  {
    name: "staffels",
    title: "Genereer staffels",
    instruction:
      "Genereer staffel bestanden. Deze kunnen door de contactpersonen goedgekeurd worden" +
      "Het opnieuw vrijgeven van de open-data is nog steeds mogelijk; Data aanpassen door middel van 'Samenvoegen van data' kan niet meer",
    baseEndpoint: "/beheer/staffel",
    actions: [
      {
        name: "generate-staffels",
        instruction: "Genereer staffelbestanden per departement",
        buttonText: "Genereer staffels",
        actionType: ActionTypes.SimpleApiCall,
        showProgress: true,
      },
      {
        name: "send-staffel-mail",
        instruction:
          "Stuur alle contactpersonen een mail notificatie dat de staffels klaar staan",
        buttonText: "Verstuur mail",
        actionType: ActionTypes.SimpleApiCall,
      },
      {
        name: "download-staffels-export",
        instruction: "Download volledig staffelbestand",
        buttonText: "Download export",
        actionType: ActionTypes.FileDownload,
        fileType: "application/x-zip-compressed",
        outputFileName: "staffels.zip",
      },
    ],
  },
  {
    name: "archive-portal",
    title: "Archiveer bestanden",
    instruction:
      "Archiveer alle data voordat het portaal wordt afgesloten en opgeschoond",
    baseEndpoint: "/beheer/archive-portal",
    actions: [
      {
        name: "generate-opendata-export",
        instruction: "Genereer en download volledige export",
        buttonText: "Genereer & download",
        actionType: ActionTypes.FileDownload,
        fileType: "application/x-zip-compressed",
        outputFileName: "opendata.zip",
      },
      {
        name: "generate-archive",
        instruction: "Archiveer bestanden",
        buttonText: "Archiveer",
        actionType: ActionTypes.SimpleApiCall,
        successMessage: "Archief wordt gegenereerd, dit kan een minuut duren",
      },
      // {
      //   name: "download-archive",
      //   instruction: "Download alle gearchiveerde bestanden",
      //   buttonText: "Download",
      //   actionType: ActionTypes.FileDownload,
      //   fileType: "application/x-zip-compressed",
      //   outputFileName: "archief.zip",
      // },
      {
        name: "send-archive-dwh",
        instruction: "Verzend het meest recente archief naar datawarehouse.",
        buttonText: "Verzend archief (S3-bucket)",
        actionType: ActionTypes.SimpleApiCall,
        errorMessages: {
          [ErrorTypes.DEFAULT]:
            "Er is een fout opgetreden bij het verzenden van het archief. Probeer het later opnieuw.",
        },
        showProgress: true,
      },
    ],
  },
  {
    name: "close-portal",
    title: "Sluit het portaal",
    instruction:
      "Sluiten van het portaal. Alle aanleveringen en gegenereerde bestanden worden gearchiveerd",
    baseEndpoint: "/beheer/close-portal",
    actions: [
      {
        name: "cleanup-component",
        instruction:
          "Opschonen van het portaal",
        buttonText: "Opschonen",
        actionType: ActionTypes.CustomComponent,
        componentName: "cleanup-component",
      },
    ],
  },
];

export default sections;
